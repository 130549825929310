import React from "react";
import "./inventry.css";
import inventry1 from "../images/inventry1.png";
import deep1 from "../images/deepi.png";
import deep3 from "../images/deep3.png";
import Footer from "./footer";
import FeaturemirrorPage from "./FeaturemirrorPage";
import FeaturesPage from "./FeaturePage";
import deep4 from "../images/deep4.png";

import InventryHeading from "./InventryHeading";
import Navbar from "./Navbar";
const Backtesting = () => {
  return (
    <div>
      <div className="large-screen">
        <Navbar />
        <div className="details-background pd60">
          <div class="grid-main-container">
            <div class="grid-container">
              <div className="row">
                <div class="col-md-6 ">
                  <InventryHeading heading="B" subheading="Backtesting." />
                </div>
                <div className="col-md-6 pt60">
                  <img
                    className="invest-img"
                    src={deep1}
                    alt="Investment Advisory"
                  />
                </div>
                <div className="col-md-12">
                  <p className="inv-sub-nor">
                    Stress test your custom strategies over 20 years of data. On
                    all the exchanges of the world
                  </p>
                </div>

                <div
                  className="col-md-12"
                  style={{ textAlign: "center" }}
                ></div>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div className="featurepage1 pt40 pd60">
          <div>
            <div class="grid-main-container">
              <div class="grid-container">
                <div style={{ display: "flex", flexFlow: "row" }}>
                  <div className="details-box-feature "></div>
                  <h1 className="featurepage-heading ">What we do?</h1>
                </div>
                <p className="featurepage-para pt20">
                  We aim to double your investment principle in less than 3
                  years by giving you a fully guided investment experience{" "}
                </p>
              </div>
            </div>
            <br />{" "}
            <div class="grid-main-container">
              <div class="grid-container">
                <FeaturesPage
                  img1={inventry1}
                  title1="Trade Ideas"
                  title2="Personalized Position Sizing"
                  title3="Risk Management"
                  title4="Reallocation Guidance"
                  title5="Exit strategy"
                  des1=" Executable trade ideas when opportunities arise in the market."
                  des2="Investment quantities recommended based on your capital."
                  des3="We use advanced data analysis techniques to minimize your losses"
                  des4=" Investment has one limit - CAPITAL. We help you reallocate your money into better opportunities as they arise"
                  des5="Recessions are cyclical 1992, 2000,2008, 2020, and are bound to occur. We help you with an exit strategy when the market is bearish."
                />
              </div>
            </div>
            <div
              className="featurepage2 pt40 pd60"
              style={{ backgroundColor: "#000000", marginTop: "60px" }}
            >
              <div class="grid-main-container">
                <div class="grid-container">
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      paddingTop: "40px",
                    }}
                  >
                    <div className="details-box-feature "></div>
                    <h1 className="featurepage-heading  ">
                      Running a Strategy
                    </h1>
                  </div>

                  <br />

                  <FeaturemirrorPage
                    title1="Worldwide exchanges"
                    title4="Multiple Strategies in Parallel"
                    des1="Test your strategy’s resilience on Indian, US, London, German, Chinese and other markets."
                    des4=" Why stop at a single strategy when you can run multiple strategies."
                    img={deep3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="featurepage1 pt60 ">
          <div class="grid-main-container">
            <div class="grid-container">
              <div style={{ display: "flex", flexFlow: "row" }}>
                <div className="details-box-feature "></div>
                <h1 className="featurepage-heading ">Analyse</h1>
              </div>

              <FeaturesPage
                title1="Strategy Reports "
                title2="Performance Analysers"
                title3="Custom Analysers"
                des1=" Analyse strategy performance as a portfolio or a group of script basis"
                des2="Net Profit, winning/losing % on trade, drawdowns and KPI's like Sharpe Ratio, System Quality Number (SQN) are readily available in Deep-I"
                des3="Not satisfied with industry standard measurements? We'll make an analyser as you want it to be"
                img1={deep4}
              />
            </div>
          </div>
        </div>
        <div className="grid-main-container">
          <div className="grid-container">
            <div class="card-deck  ">
              {" "}
              <div
                class="card text-white  mb-5 price-card"
                style={{ background: "#000" }}
              >
                {/* <center> */}
                <h5 class="card-title">
                  <div className="price-tag">
                    <h1
                      style={{
                        textAlign: "left",
                        fontSize: "35px",
                        fontWeight: "900",
                        padding: "25px",
                      }}
                    >
                      Basic
                    </h1>
                  </div>
                </h5>

                <div style={{ margin: "auto", textAlign: "center" }}>
                  <div className="prices">
                    <p className="priceText">
                      ₹{" "}
                      <span
                        style={{
                          color: "#b3e789",
                          paddingLeft: "10px",
                        }}
                      >
                        999/-
                      </span>
                    </p>
                  </div>

                  <a
                    href="https://pages.razorpay.com/pl_HFlPqRBwyivZ4l/view"
                    class="btn  "
                    style={{
                      backgroundColor: "#b3e789",
                      color: "#000",
                    }}
                  >
                    Get Started
                  </a>
                </div>
                <div className="priceText2 pt60">
                  <h5 style={{ fontSize: "25px", marginLeft: "8px" }}>
                    You will get
                  </h5>
                  <ul style={{ marginLeft: "-11px" }}>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      One Strategy
                    </li>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      One Company
                    </li>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      One Report
                    </li>{" "}
                  </ul>
                </div>
                {/* </center> */}
              </div>
              <div
                class="card text-white  mb-5 price-card"
                style={{ background: "#000" }}
              >
                {/* <center> */}
                <h5 class="card-title">
                  <div className="price-tag">
                    <h1
                      style={{
                        textAlign: "left",
                        fontSize: "35px",
                        fontWeight: "900",
                        padding: "25px",
                      }}
                    >
                      Standard
                    </h1>
                  </div>
                </h5>

                <div style={{ margin: "auto", textAlign: "center" }}>
                  <div className="prices">
                    <p className="priceText">
                      ₹{" "}
                      <span
                        style={{
                          color: "#b3e789",
                          paddingLeft: "10px",
                        }}
                      >
                        4999/-
                      </span>
                    </p>
                  </div>

                  <a
                    href="https://pages.razorpay.com/pl_HFlUCzOOknBz4B/view"
                    class="btn  "
                    style={{
                      backgroundColor: "#b3e789",
                      color: "#000",
                    }}
                  >
                    Get Started
                  </a>
                </div>
                <div className="priceText2 pt60">
                  <h5 style={{ fontSize: "25px", marginLeft: "8px" }}>
                    You will get
                  </h5>
                  <ul style={{ marginLeft: "-11px" }}>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      One Strategy
                    </li>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      50 Company
                    </li>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      Multiple Report
                    </li>{" "}
                  </ul>
                </div>
                {/* </center> */}
              </div>
              <div
                class="card text-white  mb-5 price-card"
                style={{ background: "#000" }}
              >
                {/* <center> */}
                <h5 class="card-title">
                  <div className="price-tag">
                    <h1
                      style={{
                        textAlign: "left",
                        fontSize: "35px",
                        fontWeight: "900",
                        padding: "25px",
                      }}
                    >
                      Premium
                    </h1>
                  </div>
                </h5>

                <div style={{ margin: "auto", textAlign: "center" }}>
                  <div className="prices">
                    <p className="priceText">
                      ₹{" "}
                      <span
                        style={{
                          color: "#b3e789",
                          paddingLeft: "10px",
                        }}
                      >
                        9999/-
                      </span>
                    </p>
                  </div>

                  <a
                    href="https://pages.razorpay.com/pl_HFlVigM78gT9EN/view"
                    class="btn  "
                    style={{
                      backgroundColor: "#b3e789",
                      color: "#000",
                    }}
                  >
                    Get Started
                  </a>
                </div>
                <div className="priceText2 ">
                  <h5
                    style={{
                      fontSize: "25px",
                      marginLeft: "8px",
                      marginTop: "10px",
                    }}
                  >
                    You will get
                  </h5>
                  <ul style={{ marginLeft: "-11px" }}>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      One Strategy
                    </li>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      Unlimited reports
                    </li>
                    <li style={{ fontSize: "18px", paddingTop: "5px" }}>
                      All company run on 1 exchange
                    </li>{" "}
                  </ul>
                </div>
                {/* </center> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default Backtesting;
