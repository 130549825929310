import React from "react";
import './MainHeading.css';
function MainHeading(props) {
  return (
    <div className="heading-div" >
      <div className="overlapping-c-div">
        <h1 className="overlapping-heading">{props.heading}</h1>
      <div className="overlap-div">
        <h1 className="overlapping-main  ">{props.subheading} </h1>
      </div>
      </div>
      
      <h4  className="h4-class-product">{props.subpara}</h4>
      <hr className="hr-details"></hr>
    </div>
  );
}
export default MainHeading;
