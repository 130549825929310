import React from "react";
import "./Deep.css";
import deep1 from "../images/deepi.png";
import deep2 from "../images/deep2.png";
import deep3 from "../images/deep3.png";
import deep4 from "../images/deep4.png";
import Navbar from "./Navbar";
import FeaturesPage from "./FeaturePage";
import Subscribe from "./Subscribe";
import FeaturemirrorPage from "./FeaturemirrorPage";
import Footer from "./footer";
import MainHeading from "./MainHeading";

function Deep() {
  return (
    <div>
       <div className="large-screen">
      <Navbar />
      <div className="details-background">
        <div class="grid-main-container">
          <div class="grid-container">
            <div className="row">
              <div class="col-sm-8 col-md-6 col-lg-6 col-xl-6 pd60">
                <MainHeading
                  heading="D"
                  subheading="Deep-I"
                  subpara="Backtesting and Algo-Trading at its peak"
                />

                <h2 className="play-text "> Sign Up For Beta </h2>

                <Subscribe className="sub-cycle" subscribe="deepi" />
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <img className=" img-fluid cycle-img " style={{paddingTop:"10rem"}} src={deep1} alt="Img Class" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="grid-main-container">
        <div class="grid-container">
          <div class="col-12">
            <div className="cycle-div pt40">
              <div className="details-box"></div>{" "}
              <h1 className="cycle-div-heading"> Lorem ipsum</h1>
              <p className="cycle-div-para pt20">
                A tool to test your strategies through multiple angles and
                viewpoints in the real market, Unlike traditional backtesting
              </p>
            </div>
          </div>
        </div>
        </div>
      */}

      {/* <div class="col-12">
          <div class="grid-main-container">
            <div class="grid-container">
              <div className="cycle-div pt40">
                <div className="details-box"></div>{" "}
                <h1 className="cycle-div-heading"> Lorem ipsum</h1>
                <p className="cycle-div-para pt20">
                  A tool to test your strategies through multiple angles and
                  viewpoints in the real market, Unlike traditional backtesting
                </p>
              </div>
            </div>
          </div>
        </div> */}

      <div className="featurepage1 ">
        <div class="grid-main-container">
          <div class="grid-container">
            <div
              style={{ display: "flex", flexFlow: "row", paddingTop: "40px" }}
            >
              <div className="details-box-feature "></div>
              <h1 className="featurepage-heading ">Designing a Strategy</h1>
            </div>
            {/* <p className="featurepage-para ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum{" "}
              </p>

              <br /> */}

            <FeaturesPage
              title1="122 Indicators"
              title2="Custom Indicators"
              title3="Rebalancing Logics"
              title4="Multiple Timeframes"
              title5="Stop-Loss"
              title6="Multiple order types"
              des1=" A host of popular Built in Indicators to build strategies"
              des2="If 122 is not enough, we’ve got you covered. We work with you to develop a custom indicator."
              des3="You can define Portfolio rebalancing conditions based on various parameters like performance, ROC, Time, etc"
              des4=" Design your strategy to work on an hour/day/week/month basis."
              des5="Configure stop-loss for your strategy, based on a fix, calculated or conditional values."
              des6="Market, Close, Limit, Stop & StopLimit orders to give superpowers to your strategy"
              img1={deep2}
            />
          </div>
        </div>

        <div
          className="featurepage2 pt40 pd60"
          style={{ backgroundColor: "#000000", marginTop: "60px" }}
        >
          <div class="grid-main-container">
            <div class="grid-container">
              <div
                style={{ display: "flex", flexFlow: "row", paddingTop: "40px" }}
              >
                <div className="details-box-feature "></div>
                <h1 className="featurepage-heading  ">Running a Strategy</h1>
              </div>
              {/* <p className="featurepage-para ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum{" "}
            </p> */}

              <br />

              <FeaturemirrorPage
                title1="Worldwide exchanges"
                title2="Portfolio formation"
                title3="Rebalancing logics"
                title4="Multiple Strategies in Parallel"
                des1="Test your strategy’s resilience on Indian, US, London, German, Chinese and other markets."
                des2="Why run on 1 Stock when you can do it on the whole market?
          We run your strategy on the market to stimulate real world scenarios. Instead of just running it on 1 stock and taking decisions based on that"
                des3="Define Adaptive and fixed rebalancing logics while running the strategy. Stimulating low/no cash scenarios and deciding between 2 good stocks is a challenge your strategy will be tested on"
                des4=" Why stop at a single strategy when you can run multiple strategies."
                img={deep3}
              />
            </div>
          </div>
        </div>
        <div className="featurepage1 pt60 pd60">
          <div class="grid-main-container">
            <div class="grid-container">
              <div style={{ display: "flex", flexFlow: "row" }}>
                <div className="details-box-feature "></div>
                <h1 className="featurepage-heading ">Analyse</h1>
              </div>
              {/* <p className="featurepage-para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum{" "}
            </p> */}

              {/* <br /> */}

              <FeaturesPage
                title1="Strategy Reports "
                title2="Performance Analysers"
                title3="Custom Analysers"
                // title4="Multiple Timeframes"
                // title5="Stop-Loss"
                // title6="Multiple order types"

                des1=" Analyse strategy performance as a portfolio or a group of script basis"
                des2="Net Profit, winning/losing % on trade, drawdowns and KPI's like Sharpe Ratio, System Quality Number (SQN) are readily available in Deep-I"
                des3="Not satisfied with industry standard measurements? We'll make an analyser as you want it to be"
                // des4=" Design your strategy to work on an hour/day/week/month basis."
                // des5="Configure stop-loss for your strategy, based on a fix, calculated or conditional values."
                // des6="Market, Close, Limit, Stop & StopLimit orders to give superpowers to your strategy"
                img1={deep4}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      </div>
    </div>
  );
}

export default Deep;
