import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import logo from "../images/logo.svg";
import "./Navbar.css";

function Navbar() {
  const [dropDownOpenFlag, setDropDownOpen] = useState(false);
  const [dropDownOpenFlag2, setDropDownOpen2] = useState(false);
  const [click, setclick] = useState(false); //mobileview icon click

  const handleClick = () => setclick(!click);
  const closemobileMenu = () => {
    setclick(false);
  };

  const toggle = () => {
    setDropDownOpen(!dropDownOpenFlag);
  };

  const onMouseEnter = () => {
    setDropDownOpen(true);
  };

  const onMouseLeave = () => {
    setDropDownOpen(false);
  };
  const toggle2 = () => {
    setDropDownOpen2(!dropDownOpenFlag2);
  };

  const onMouseEnter2 = () => {
    setDropDownOpen2(true);
  };

  const onMouseLeave2 = () => {
    setDropDownOpen2(false);
  };

  return (
    <div>
      <nav className="navbar fixed-top">
        <Link to="/" className="logo-title">
          {" "}
          <img className="logo" src={logo} alt="Cycle Logo" /> Clearmind
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link className="nav-links" to="/about" onClick={closemobileMenu}>
              About
            </Link>
          </li>
          <li class="nav-item " onMouseLeave={onMouseLeave}>
            {/* <a class="nav-link dropdown-toggle" href="/product" id="navbarDropdown" className ='nav-links' role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         Product
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/productdetails">Cycle</a>
          <a class="dropdown-item" href="/productdetails">Deep-i</a> */}
            {/* <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a> */}
            {/* </div> */}
            <Dropdown
              onMouseOver={onMouseEnter}
              isOpen={dropDownOpenFlag}
              toggle={toggle}
            >
              <DropdownToggle tag="span" className="nav-links">
                Products
              </DropdownToggle>
              <DropdownMenu
                onMouseLeave={onMouseLeave}
                style={{
                  textAlign: "left",
                  backgroundColor: "#171717",
                  marginTop: "0px",
                  // color: "#fff",
                  width: "100%",
                  height: "auto",
                }}
              >
                <DropdownItem>
                  <Link
                    style={{
                      color: "white",
                      fontWeight: "400",
                      fontSize: "1.5rem",
                    }}
                    to="/cycle"
                    className="link-check"
                  >
                    Cycle
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    style={{
                      color: "white",
                      fontWeight: "400",
                      fontSize: "1.5rem",
                    }}
                    to="/deep"
                  >
                    Deep-i
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
          <li class="nav-item" onMouseLeave={onMouseLeave2}>
            <Dropdown
              onMouseOver={onMouseEnter2}
              isOpen={dropDownOpenFlag2}
              toggle={toggle2}
            >
              <DropdownToggle tag="span" className="nav-links">
                Services
              </DropdownToggle>
              <DropdownMenu
                onMouseLeave={onMouseLeave2}
                style={{
                  textAlign: "left",
                  backgroundColor: "#171717",
                  marginTop: "0px",
                  color: "#fff",
                }}
              >
                <DropdownItem>
                  <Link
                    to="/invest-advisory"
                    style={{
                      color: "white",
                      fontWeight: "400",
                      fontSize: "1.5rem",
                    }}
                  >
                    Investment Advisory
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/backtesting"
                    style={{
                      color: "white",
                      fontWeight: "400",
                      fontSize: "1.5rem",
                    }}
                  >
                    Backtesting
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-links" to="/blog" onClick={closemobileMenu}>
              Blog{" "}
            </Link>
          </li> */}
          <li className="nav-item">
            <a
              className="nav-links"
              href="https://medium.com/iamclearmind"
              onClick={closemobileMenu}
            >
              Blog{" "}
            </a>
          </li>
          <li className="nav-item">
            <Link className="nav-links" to="/contact" onClick={closemobileMenu}>
              Contact{" "}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Navbar;
