import React from "react";
import "./inventry.css";
import inventry1 from "../images/inventry1.png";
import Frame7 from "../images/Frame7.png";
import Footer from "./footer";
import FeaturesPage from "./FeaturePage";
import Subscribe from "./Subscribe";
import InventryHeading from "./InventryHeading";
import Navbar from "./Navbar";
function Inventry() {
  return (
    <div>
      <div className="large-screen">
        <Navbar />
        <div className="details-background pd60">
          <div class="grid-main-container">
            <div class="grid-container">
              <div className="row">
                <div class="col-md-6 ">
                  <InventryHeading
                    heading="I"
                    subheading="Investment Advisory"
                    // subpara=" Everybody is an investor, without the time and expertise to make investment decisions"
                  />
                </div>
                <div className="col-md-6 pt60">
                  <img
                    className="invest-img"
                    src={Frame7}
                    alt="Investment Advisory"
                  />
                </div>
                <div className="col-md-12">
                  <p className="inv-sub-nor">
                    Everybody is an investor, without the time and expertise to
                    make investment decisions
                  </p>
                </div>

                <div className="col-md-12" style={{ textAlign: "center" }}>
                  {/* <p className="inv-sub">
                  We aim to double your investment principle in less than 3
                  years by giving you a fully guided investment experience
                </p> */}
                  <div class="line-container">
                    <span class="line-style">&nbsp;</span>
                    <span class="text-style">Upcoming</span>
                    <span class="line-style">&nbsp;</span>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div className="featurepage1 pt40 pd60">
          <div>
            <div class="grid-main-container">
              <div class="grid-container">
                <div style={{ display: "flex", flexFlow: "row" }}>
                  <div className="details-box-feature "></div>
                  <h1 className="featurepage-heading ">What we do?</h1>
                </div>
                <p className="featurepage-para pt20">
                  We aim to double your investment principle in less than 3
                  years by giving you a fully guided investment experience{" "}
                </p>
              </div>
            </div>
            <br />
            <div class="grid-main-container">
              <div class="grid-container">
                <FeaturesPage
                  img1={inventry1}
                  title1="Trade Ideas"
                  title2="Personalized Position Sizing"
                  title3="Risk Management"
                  title4="Reallocation Guidance"
                  title5="Exit strategy"
                  des1=" Executable trade ideas when opportunities arise in the market."
                  des2="Investment quantities recommended based on your capital."
                  des3="We use advanced data analysis techniques to minimize your losses"
                  des4=" Investment has one limit - CAPITAL. We help you reallocate your money into better opportunities as they arise"
                  des5="Recessions are cyclical 1992, 2000,2008, 2020, and are bound to occur. We help you with an exit strategy when the market is bearish."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="subscribe-background">
          <div class="grid-main-container">
            <div class="grid-container">
              <div class="col-12 pd40">
                <div className="cycle-div pt40  ">
                  <div className="detail-div">
                    <div className="details-box"></div>{" "}
                    <h1 className="cycle-div-heading"> Join the Waitlist.</h1>
                    <p className="cycle-div-para pt20 ">
                      We're eagerly waiting for our Investment Advisory License
                      from SEBI.As soon as we get it, you'll be the first to
                      know.
                      <br /> Subscribe below.{" "}
                    </p>
                  </div>
                </div>

                <div className="subscribe-form pd60">
                  <Subscribe subscribe="IA" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Inventry;
