import React from 'react';
import './Products.css';



const Products = props =>{
    return(
        
         <div  className="objective1-div container-fluid pt60" >
         <div className="img1-background">
               <img className="img1 img-fluid pt40 "
               src={props.imgsrc} alt="Gilly"/>
               
         </div>
         <div className="objective2">
           <h3 className="objective1-heading">{props.title}</h3>
           <p className="objective1-para pt20"> {props.description}</p>
         </div>
         </div> 
        
    )
}
export default Products