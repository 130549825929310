import React from "react";
// import { uid } from "uid";
// import fire from "./firebase";
import Footer from "./footer";
import contactimg from "../images/contactimg.png";
import "./contact.css";
import Navbar from "./Navbar";
import "firebase/database";
import {
  FormWithConstraints,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints";

class Contact extends React.Component {
  handleChange = (e) => {
    this.form.validateFields(e.target);
    console.log(e.target);
    console.log("cliked");
  };

  contactSubmit = (e) => {
    e.preventDefault();

    this.form.validateFields();

    if (!this.form.isValid()) {
      console.log("form is invalid: do not submit");
    } else {
      console.log("form is valid: submit");
    }
    console.log("cliked");
  };

  render() {
    return (
      <div>
         <div className="large-screen">
        <Navbar />
        <div className="container-fluid">
          <div class="row g-0">
            <div class="col-sm-6 col-md-6  col-lg-6 col-xl-6">
            <div className="about-obne-div">
              <h1 className="cont-heading">Say</h1>
              <div className="contact-one">
                <h1 className="contact-sub">Hello!</h1>
              </div>
              </div>
            </div>

            <div
              class="col-sm-6 col-md-6  col-lg-6 col-xl-6"
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <div className="contactform-background">
                <FormWithConstraints
                  ref={(form) => (this.form = form)}
                  onSubmit={this.contactSubmit}
                  noValidate
                  className="contact-form "
                >
                  <div className="col-md-12 check-size">
                    <h1 className="contact1-heading pt60"> Contact Us</h1>
                    <label for="heading" className="form-label pt20">
                      Name
                    </label>
                    <input
                      name="name"
                      size="30"
                      placeholder="Name"
                      required
                      onChange={this.handleChange}
                      className="form-control form-input"
                    />
                    <FieldFeedbacks for="name">
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                    <label for="link" className="form-label pt20">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      size="30"
                      placeholder="Email"
                      required
                      onChange={this.handleChange}
                      className="form-control form-input"
                    />
                    <FieldFeedbacks for="email">
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                    <label for="desc" className="form-label pt20">
                      Message
                    </label>
                    <textarea
                      name="comments"
                      rows="7"
                      col="40"
                      placeholder="Message"
                      required
                      minLength={5}
                      maxLength={50}
                      onChange={this.handleChange}
                      className="form-control form-input"
                    />
                    <FieldFeedbacks for="comments">
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                  </div>
                  <div className="col-md-12 btn-line">
                    <button
                      style={{ background: "#B3E789" }}
                      className="btn btn-lg contact-button"
                    >
                      Send Message
                    </button>
                  </div>
                </FormWithConstraints>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            className="contact-background img-fluid  contact-img"
            src={contactimg}
            alt="Contact Backgroung"
          />
        </div>
        <Footer />
        </div>
      </div>
    );
  }
}

export default Contact;
