import React from 'react';
import './Products.css';



const Products2 = props =>{
    return(
        
        <div  className="objective1-div container-fluid pt60" >
        <div className="objective1">
          <h3 className="objective1-heading">{props.title}</h3>
          <p className="objective1-para pt20">{props.description}</p>
        </div>
        <div className="img2-background pt40">
              <img className="img2 img-fluid"
              src={props.imgsrc}  alt="Gilly"/>
              
        </div>
        </div>        
    )
}
export default Products2