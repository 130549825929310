import React from "react";
// import Subscribe from "./Subscribe";
import MainHeading from "./MainHeading";
import MobileFeature from "./MobileFeature";
import { Link } from "react-router-dom";
import "./DownloadButton.css";
import { Modal } from "react-bootstrap";
import QR from "../images/clearmind-qr.png";
// import { Button } from "reactstrap";
// import FeaturesPage from './FeaturePage'
import Footer from "./footer";
import { FaGooglePlay } from "react-icons/fa";
// import { AiFillApple } from "react-icons/ai";
import "./cycle.css";
import group from "../images/Group.png";
import Navbar from "./Navbar";
function Cycle() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <div className="large-screen">
        <Navbar />
        <div className="details-background">
          <div class="grid-main-container">
            <div class="grid-container">
              <div className="row " style={{ paddingBottom: "12%" }}>
                <div class="col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <MainHeading
                    heading="C"
                    subheading="Cycle"
                    subpara="Everyone invests. The trick is to do it right."
                  />
                  <div className="ul-class ">
                    <ul className="icon-list ">
                      <div style={{ display: "flex" }}>
                        <Link onClick={() => setModalShow(true)}>
                          {" "}
                          <li className="li-class">
                            <span className="play-icon">
                              <FaGooglePlay size={20} />
                            </span>
                            <h4 className="play-text">Download on Playstore</h4>
                          </li>{" "}
                        </Link>
                      </div>
                      <Modal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        size="lg"
                        centered
                      >
                        <Modal.Body style={{ padding: "60px" }}>
                          <div className="container">
                            {" "}
                            <div className="row">
                              <div className="col-sm-6">
                                <h4
                                  className="qr-heading"
                                  style={{ color: "#000" }}
                                >
                                  Find out if you’ve made it. scan this QR code.
                                </h4>
                                <ul className="qr-ui">
                                  <li className="qr-li">
                                    open your phone camera and point it at the
                                    QR code.
                                  </li>
                                  <li className="qr-li">
                                    alternatively, download any QR code scanner
                                    to scan.
                                  </li>
                                  <li className="qr-li">
                                    click on the link generated to download
                                    CYCLE.
                                  </li>
                                </ul>
                              </div>
                              <div className="col-sm-6">
                                <img src={QR} alt="Qr" width="100%" />
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      {/* <li className="li-class">
                        <span className="play-icon">
                          <AiFillApple size={25} />
                        </span>
                        <h4 className="play-text"> Download on Playstore </h4>
                      </li> */}
                    </ul>
                  </div>
                  {/* <h2 className="play-text"> Sign Up For Beta </h2>

                <Subscribe className="sub-cycle" subscribe="BetaCycle" /> */}
                </div>

                <div class="col-md-6">
                  <img
                    className=" cycle-img img-fluid pt60"
                    src={group}
                    alt="Img Class"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 pt40 ">
          <div className="grid-main-container">
            <div class="grid-container">
              <div className="cycle-div ">
                <div className="detail-div">
                  <div className="details-box"></div>{" "}
                  <h1 className="cycle-div-heading"> Philosophy</h1>
                  <p className="cycle-div-para">
                    Demand and supply creates market movement. We use a
                    combination of trend following and risk management to
                    suggest buy and sell signals in cycle
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*                
                <div className="rating-product">
                <Rating/>
</div> */}
        <div className="mobile-background-div">
          <div className="mobile-background">
            <div class="col-12 pt60 ">
              <div className="grid-main-container">
                <div class="grid-container">
                  <div className="detail-div">
                    <div className="details-box "></div>
                    <h1 className="mobile-heading">
                      Cycle Helps You Make Investment Decisions In Minimum Time
                    </h1>
                    {/* <p style={{textAlign:"justify"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum </p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="grid-main-container">
              <div class="grid-container">
                <MobileFeature />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="grid-main-container">
          <div className="grid-container">
            <div class="card-deck pt60 pd60">
              <div
                class="card text-white mb-3 price-card"
                style={{ background: "#000" }}
              >
                <h5 class="card-title">
                  <div className="price-tag">
                    <h1 style={{ textAlign: "left" }}>Free</h1>
                  </div>
                </h5>
                <div className="prices">
                  <p className="priceText">
                    It's Free
                    <span
                      style={{
                        opacity: "0.8",
                        color: "#b3e789",
                        paddingLeft: "10px",
                      }}
                    ></span>
                  </p>{" "}
                </div>{" "}
                <Link
                  href="#"
                  class="btn "
                  style={{
                    margin: "auto",
                    marginLeft: "10%",
                    backgroundColor: "#b3e789",
                    color: "#000",
                  }}
                >
                  Get Started
                </Link>
                <div className="priceText2">
                  <h4>
                    <b>You Will Get </b>
                  </h4>
                  <h5> 10 Company </h5>
                  <h5>One Watchlist</h5> <h5>One Country</h5>{" "}
                  <h5>Performance Details</h5> <h5>Access to University</h5>
                </div>
              </div>

              <div
                class="card text-white  mb-3 price-card"
                style={{ background: "#000" }}
              >
                <h5 class="card-title">
                  {" "}
                  <div className="price-tag">
                    <h1 style={{ textAlign: "left" }}>Premium</h1>
                  </div>
                </h5>
                <div className="prices">
                  <p className="priceText">
                    ₹{" "}
                    <span
                      style={{
                        color: "#b3e789",
                        paddingLeft: "10px",
                      }}
                    >
                      200/-
                    </span>
                  </p>
                </div>{" "}
                <Link
                  href="#"
                  class="btn "
                  style={{
                    margin: "auto",
                    marginLeft: "10%",
                    backgroundColor: "#b3e789",
                    color: "#000",
                  }}
                >
                  Get Started
                </Link>
                <div className="priceText2">
                  <h4>
                    <b>You will get</b>
                  </h4>
                  <h5>Everything in free </h5>
                  <h5>Multiple Watchlist</h5> <h6>Multiple Countries</h6>
                </div>
              </div>

              <div
                class="card text-white  mb-3 price-card"
                style={{ background: "#000" }}
              >
                <h5 class="card-title">
                  <div className="price-tag">
                    <h1 style={{ textAlign: "left" }}>Alert</h1>
                  </div>
                </h5>
                <div className="prices">
                  <p className="priceText">
                    ₹{" "}
                    <span
                      style={{
                        opacity: "",
                        color: "#b3e789",
                        paddingLeft: "10px",
                      }}
                    >
                      200/-
                    </span>
                  </p>
                </div>{" "}
                <Link
                  href="#"
                  class="btn "
                  style={{
                    margin: "auto",
                    marginLeft: "10%",
                    backgroundColor: "#b3e789",
                    color: "#000",
                  }}
                >
                  Get Started
                </Link>
                <div className="priceText2">
                  <h5>
                    <b>You will get</b>
                  </h5>
                  <h6>Lorem Ipsum has been the industry's</h6>
                  <h6>Lorem Ipsum has been the industry's</h6>{" "}
                  <h6>Lorem Ipsum has been the industry's</h6>{" "}
                  <h6>Lorem Ipsum has been the industry's</h6>{" "}
                  <h6>Lorem Ipsum has been the industry's</h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
export default Cycle;
