import React from "react";
import "./Subscribe.css";

class Subscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.state = {
      action: `https://iamclearmind.us7.list-manage.com/subscribe/post?u=f435ea09c8c1601c92485c653&amp;id=41cf8e8066&SIGNUP=${this.props.subscribe}`,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <form
        className="form-inline"
        style={{ display: "flex" }}
        action={this.state.action}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        novalidate
      >
        <label>
          <input
            style={{ color: "#E1EEFF", background: "#141414" }}
            type="email"
            // value=""
            name="EMAIL"
            className="email   subscribe-input  form-control-lg "
            id="mce-EMAIL"
            value={this.state.value}
            onChange={this.handleChange}
            placeholder="example@gmail.com"
            onfocus="this.placeholder = ''"
            onblur="this.placeholder = 'john@gmail.com'"
            required
          />
        </label>
        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_f435ea09c8c1601c92485c653_41cf8e8066"
            tabindex="-1"
            value=""
          />
        </div>
        <div class="col-auto my-1">
          <button
            style={{ color: "#000000", background: "#B3E789" }}
            type="submit"
            className=" subscribe-button btn "
          >
            Subscribe
          </button>
        </div>
      </form>
      // <div className="form-inline" >
      // <form  className="sub-form" action="https://iamclearmind.us7.list-manage.com/subscribe/post?u=f435ea09c8c1601c92485c653&amp;id=41cf8e8066" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
      //          <input style={{ "color":"#E1EEFF","background": "#141414" }} type="email" value="" name="EMAIL" className="email " id="mce-EMAIL" value={this.state.value} onChange={this.handleChange} placeholder="email address" required/>
      //       <input  style={{ "color":"#000000","background": "#B3E789" }}type="submit" value="Subscribe" name="subscribe"  className="button "/>

      //   </form>
      //  </div>
    );
  }
}
export default Subscribe;
