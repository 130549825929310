import React from "react";
import "./landingpage.css";
// import { MDBIcon } from "mdbreact";
//importing components
import Feature from "./Feature";
import Navbar from "./Navbar";
import Subscribe from "./Subscribe";
import Footer from "./footer";
import Products from "./Products";
import Products2 from "./Products2";
//impoting images
import main from "../images/landing-background.jpg";
// import questionbar from "../images/question-bar.png";
import gilly from "../images/undraw_check_boxes_m3d0 1.svg";
import linechart from "../images/66.svg";
// const slideData = [
//   {
//     index: 0,
//     headline: "Deep-i",
//     para: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae ipsum icing elit. ",
//     src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
//   },
//   {
//     index: 1,
//     headline: "Cycle",
//     para: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae ipsum icing elit. ",
//     src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/forest.jpg'
//   },
//   {
//     index: 2,
//     headline: "Wealth Management",
//     para: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae ipsum icing elit. ",
//     src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/guitar.jpg"
//   },
// ];

function LandingPage() {
  return (
    <div className="large-screen">
  
      <Navbar />
      <img className="main-img" src={main} alt="Main Img" />
      {/* <img
        className="question-bar img-fluid "
        src={questionbar}
        alt="QuestionBar"
      /> */}
      <div className="mainx">
        <h1 className="main-heading">
          Invest{" "}
          <span className="finance" style={{ color: "#FFFFFF" }}>
            Better
          </span>{" "}
        </h1>
        <p className="content-para-landing">
          System Driven Investments For Superior Returns
        </p>
        {/* <div class="question">
          <h1 className="subheading">Who are we?</h1>
          <div className="answer">
            <p className="subpara">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae
              ipsum icing elit.{" "}
            </p>
            <button className="sub-button  btn justify-content-left align-items-center">
              Get Start <MDBIcon icon="angle-right" />
            </button>
          </div>
        </div> */}
      </div>

      {/* <div className="card-background"></div> */}
         <div class="grid-main-container">
          <div class="grid-container"> 
      <div className="services ">
        <div className="heading">
          <div className="reactangle1"></div>
          <h1 className="card-heading">products & services</h1>
        </div>
        <p className="card-para pt40">
          A suite of products & services to help you invest better. Make
          smarter, data-driven decisions. Whether you're a novice or an expert,
          we have something for everyone.{" "}
        </p>
      </div>
      
      <Feature  />
      </div>
      </div>
 
      <div className="subscribe-start pt60 pd60">
      <div class="grid-main-container">
          <div class="grid-container">
        <div className="objective-check">
          <div className="heading">
            <div className="reactangle"></div>
            <h1 className="card-heading">System is the Answer</h1>
          </div>
        </div>
        <Products
          imgsrc={gilly}
          title="Objective Investing"
          description="Investment decisions of buying or selling are driven by fixed rules or basis which are based on fact data not our fiction"
        />
        <Products2
          imgsrc={linechart}
          title="Adaptive Investing"
          description="Investments should have the flexibility to adapt to the most rewarding investment option"
        />
      </div>
      </div>
      </div>
   
      <div className=" pd60 pt60">
          <div class="grid-main-container">
          <div class="grid-container">
        <div className="heading">
          <div className="reactangle"></div>
          <span className="card-heading">Subscribe to Stay Updated</span>
        </div>
        {/* <p className="subscribe-para ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, laborum.Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco est laborum.
        </p> */}
        <div className="subscribe-form pt20" style={{ display: "flex" }}>
          <Subscribe subscribe="Homepage" />
        </div>
      </div>
</div>
</div>
      <div className="footer-landing">
        <Footer />
      </div>
      </div>

  );
}
export default LandingPage;
