import React from "react";
import { MDBIcon } from "mdbreact";
import "./FeaturemirrorPage.css";

const FeaturemirrorPage = (props) => {
  return (
    <div className="features_img pt20">
      <div>
        <img className="img-Bussiness2 pt40" src={props.img} alt="" />
      </div>
      <div className="features1">
        <div className="icon_text">
          {props.title1 == null || props.des1 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className="check-arrow  check-arrow1 check-arrow-feature"
              />

              <h4 className="mobilef-heading heading1 mobilef-heading1 ">
                {props.title1}
              </h4>
            </div>
          )}

          <div>
            <p className="featureText pt20">{props.des1}</p>
          </div>
        </div>
        <div className="icon_text">
          {props.title2 == null || props.des2 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className=" check-arrow  check-arrow1 check-arrow-feature"
              />
              <h4 className="mobilef-heading heading1 mobilef-heading1">
                {props.title2}
              </h4>
            </div>
          )}

          <div>
            <p className="featureText pt20">{props.des2} </p>
          </div>
        </div>
        <div className="icon_text">
          {props.title3 == null || props.des3 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className=" check-arrow  check-arrow1 check-arrow-feature"
              />
              <h4 className="mobilef-heading heading1 mobilef-heading1">
                {" "}
                {props.title3}
              </h4>
            </div>
          )}

          <div>
            <p className="featureText pt20">{props.des3} </p>
          </div>
        </div>
        <div className="icon_text">
          {props.title4 == null || props.des4 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className=" check-arrow  check-arrow1 check-arrow-feature"
              />
              <h4 className="mobilef-heading heading1 mobilef-heading1">
                {props.title4}
              </h4>
            </div>
          )}

          <div>
            <p className="featureText pt20">{props.des4}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturemirrorPage;
