import React from "react";
import { MDBIcon } from "mdbreact";
import mobilefe from "../images/featureM.png";
import "./MobileFeature.css";
const MobileFeature = () => {
  return (
    <div className="MobileF">
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          margin: "2%",
          marginTop: "7%",
        }}
      >
        <div className="para_icon2 pt20">
          <div className="iconX">
            <h4 className="mobilef-heading1">Buy & Sell signals </h4>
            <MDBIcon icon="check" size="3x" className=" check-arrow " />
          </div>
          <div>
            <p className="white-text pt20">
              Unbiased signals based on demand and supply in the market
            </p>
          </div>
        </div>
        <div className="para_icon2 pt20">
          <div className="iconX">
            <h4 className="mobilef-heading1">
              Do it the right way, but in your style
            </h4>
            <MDBIcon icon="check" size="3x" className=" check-arrow" />
          </div>
          <div>
            <p className="white-text pt20">
              Timeframes for all types of investors, short term, mid term and
              long term.
            </p>
          </div>
        </div>
      </div>
      <img
        className="img-fluid-mobi img-fluid"
        src={mobilefe}
        alt="Mobile Feature"
      />
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          margin: "2%",
          marginTop: "7%",
        }}
      >
        <div className="para_icon1 pt20">
          <div className="iconX1">
            <MDBIcon icon="check" size="3x" className=" check-arrow" />
            <h4 className="mobilef-heading">Indian Markets.</h4>
          </div>
          <div>
            <p className="white-text1 pt20">
              All traded securities above rs 10 in indian market
            </p>
          </div>
        </div>
        <div className="para_icon1 pt20">
          <div className="iconX1">
            <MDBIcon icon="check" size="3x" className=" check-arrow" />
            <h4 className="mobilef-heading">
              We like risks. But only the smart ones.
            </h4>
          </div>
          <div>
            <p className="white-text1 pt20">
              Reduce risks on capital through Stop-losses with all signals
            </p>
          </div>
        </div>

        <div className="para_icon1 pt20">
          <div className="iconX1">
            <MDBIcon icon="check" size="3x" className=" check-arrow" />
            <h4 className="mobilef-heading">Learn from Legends</h4>
          </div>
          <div>
            <p className="white-text1 pt20">
              Learn the fundamentals of investment philosophy through curated
              content.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFeature;
