import React from "react";
import "./About.css";
import Footer from "./footer";
import Navbar from "./Navbar";
//image
import image4 from "../images/Frame 6.svg";
import graph from "../images/Graphic.png";
// import triangle from "../images/triangle.png";
import graphbar from "../images/chart.png";

function About() {
  return (
    <div>
      <div className="large-screen">
        <Navbar />
        <div className="about-background">
          <div class="grid-main-container">
            <div class="grid-container">
              <div className="about-obne-div">
                <h1 className="about-heading">About</h1>

                <div className="about-one">
                  <h1 className="about-heading-second pd20">
                    get to know us more
                  </h1>
                </div>
              </div>
              {/* <p className="about-para ">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae
                dolorum minima dolorum nesciunt{" "}
              </p> */}
              <div></div>
            </div>
          </div>
          <div className="container-fluid">
            <div class="row about-secTwo pt40 pd60">
              <div class="col-sm-6 col-md-6  col-lg-6 col-xl-6">
                <div className="oneSec ">
                  <div className="about-rect"></div>
                  <span className="about-subheading">Who are we?</span>
                  <p className="about-body pt20 pd60">
                    We're a wealth management advisory company with special
                    focus on equity/stock markets. We've consistently
                    outperformed industry leaders and indexes in Indian market
                    to produce outstanding returns on investment for our clients
                    over the past 4 years
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6  pd60">
                {/* <div className="green-body "> </div> */}
                <img
                  className="about-body-img "
                  src={image4}
                  alt="About Green Body"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="grid-main-container">
          <div class="grid-container">
            <div className="secondary-div pt60">
              <div className="se-div">
                <div className="about-rect1"></div>

                <span className="secondary-heading-about ">
                  What Are We Trying To Achieve With iamclearmind.com
                </span>
                <p className="secondary-para-about pt40">
                  Our story of investing generally begins with someone
                  suggesting us to buy shares of certain company. He can be your
                  friend, relative, acquaintance, banker, broker or anybody
                  else. He may be expert in picking stocks or may be a novice.
                  <br></br>
                  <br></br>
                  Alternatively, since we believe in written words more than
                  anything else and we read somewhere about equity investing and
                  people making money in equity market, we go and buy some
                  shares.
                  <br></br>
                  <br></br>
                  Best scenario is - by luck we happen to be in bull market, get
                  some profit, book it, reinvest all money in other companies
                  and so on. But on change of direction of market from bull to
                  bear we suffer losses(notional or actual), depending on when
                  you book loss or you never sell in hope of recovery. We don't
                  even talk about it because of shameful feeling about being
                  imprudent and losing in stock market.
                  <br></br>
                  <br></br>
                  Now, some people will try to study their mistakes and again
                  invest in market while some will deem it too risky or
                  unpredictable and quit the market (very big loss of
                  opportunity!).<br></br>
                  <br></br>I believe we lose in market because we don't have an{" "}
                  objective process of taking investment decisions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative-about ">
          <div class="grid-main-container">
            <div class="grid-container">
              <div className="relative-about-div pt60 ">
                <div className="rd-div">
                  <div className="about-rect2"></div>
                  <span className="relative-about-heading ">
                    Relative Strength Investing
                  </span>
                  <p className="relative-about-para pt40">
                    For your portfolio to outperform the market, it must be
                    comprised of stocks that are outperforming the market.
                    Relative strength has always been a cornerstone of our
                    investment process. It is, in our opinion, the most robust
                    and adaptable method of security analysis ever developed.
                    Despite the popular notion that such a simplistic approach
                    to security analysis can never beat the market, relative
                    strength has been shown tim time again by portfolio managers
                    and academic studies to be a viable methodology for
                    outperforming the market over time.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-main-container">
            <div class="grid-container">
              <div className="about-left-div pd60">
                <div>
                  <img
                    className="about-left-img img-fluid"
                    src={graph}
                    alt="Graph"
                  />
                </div>

                <div className="bar-right-div pt40 ">
                  <h3 className="about-right-heading  ">Objective</h3>
                  <p className="about-right-para pt10">
                    Whether the market is favoring mid-cap growth stocks,
                    dividend-paying stocks, Blue Chips, stocks with high
                    fundamental valuations, etc. our relative strength screens
                    will lead us to those stocks being rewarded most by the
                    market, with the probability that these stocks will continue
                    to be good performers in the future.
                  </p>
                  {/* <img className="triangle" src={triangle} alt="Triangle" /> */}
                  <p className="adaptive-about pt20">Adaptive</p>
                  <p className="adaptive-para pt10">
                    By building a portfolio of strong relative strength stocks,
                    the investor is allowed to participate in long term trends.
                  </p>
                  {/* <img
                  className="triangle-adaptive"
                  src={triangle}
                  alt="Adaptive Triangle"
                /> */}
                  {/* <p className="management-about pt20">
                  Money Management Philosophy
                </p>
                <p className="management-para pt10 ">
                  Relative strength measurements are undistorted by emotion or
                  personal bias. It is not necessary to interpret whether or not
                  a stock is exhibiting strong relative strength. It either is,
                  or it is not. Only stocks with strong relative strength are
                  candidates for our portfolios.
                </p> */}
                  {/* <img
                  className="management-triangle"
                  src={triangle}
                  alt="Management Triangle"
                /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-main-container  ">
          <div class="grid-container">
            <div className="technical-div pt60">
              <div className="td-div">
                <div className="about-rect3"></div>
                <span className="technical-body ">
                  Why do We use technical analysis (Charts)
                </span>
                <p className="technical-para pt40">
                  We have made immense trading profits for my clients and myself
                  precisely because We do not listen to the news or any outside
                  influences. We follow a 100% non-emotional and highly
                  disciplined mechanical trading strategy. And finally, to make
                  money trading, we need only to know that markets move- not why
                  they move. All of my trading methods are primarily based on
                  price because price is reality. Trends in motion will stay in
                  motion until they reverse.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="graph-div pt60 ">
          <img
            className="graphbar-img img-fluid "
            src={graphbar}
            alt="Graph Bar"
          />
          <div class="grid-main-container">
            <div class="grid-container">
              <p className="graphbar-para pt40  ">
                “ You should know that I rarely (if ever) talk about the news.
                My view as a trader for 38 years is that market moves create the
                news, not that the news creates market moves. Therefore, to say
                that this news event caused the market to do thus and so - is
                absurd. No one knows why the markets do anything (although many
                traders with great hubris think they know), but it is a claim
                that cannot be epistemologically defended in my view.”{"  "}{" "}
                <br />
                <span style={{ paddingLeft: "80%", color: "#b3e789" }}>
                  -Anonymous
                </span>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default About;
