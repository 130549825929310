import React from "react";
// import { MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";
import main from "../images/cycleIcon.svg";
import invest from "../images/invest.svg";
import deep from "../images/deepIcon.svg";
function Feature() {
  return (
    <div className="grid-main-container ">
<div className="grid-container">      
        <section className="p-md-3 mx-md-5 text-lg-left ">
          <div className="row text-center d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 mb-3 pt60">
              {/* <MDBIcon icon="users  fa-2x mb-4" className="feature-icon" /> */}
              <img
                className="feature-icon mb-4"
                src={invest}
                alt="Invest Advisory"
              />
              <Link to="/invest-advisory">
                <h4
            
                  className="font-weight-bold mb-4  feature-head feat-head pt30"
                >
                  Investment Advisory
                </h4>
              </Link>
              <p className=" px-2 mb-lg-0 feat-para pt20 " >
                Double your investment principle in less than 3 years by giving
                you a fully guided investment experience
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mb-3 pt60">
              {/* <MDBIcon icon="desktop  fa-2x mb-4" className="feature-icon" /> */}
              <img className="feature-icon mb-4" src={main} alt="Cycle" />
              <Link to="/cycle">
                {" "}
                <h4
                  // style={{ color: "#ffffff" }}
                  className="font-weight-bold mb-4  feat-head  feature-head pt30"
                >
                  Cycle
                </h4>
              </Link>
              <p className=" px-2 mb-lg-0 feat-para pt20">
                Demand and supply creates market movement. We use a combination
                of trend following and risk management to suggest buy and sell
                signals in cycle
              </p>
            </div>
            <div class="col-lg-4 col-md-6 mb-3 pt60">
              <img className="feature-icon mb-4 " src={deep} alt="Deep" />
              {/* <MDBIcon icon="cogs fa-2x mb-4" className="feature-icon" /> */}
              <Link to="/deep">
                <h4 class="font-weight-bold mb-4  feat-head feature-head pt30">
                  Deep-I
                </h4>
              </Link>
              <p class="px-2 mb-md-0 feat-para pt20">
                A tool to test your strategies through multiple angles and
                viewpoints in the real market, Unlike traditional backtesting
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Feature;
