import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBIcon } from "mdbreact";
import "./footer.css";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";

class footer extends React.Component {
  handlePageChange() {
    window.location.href = (
      <Iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1st%20floor,Above%20Industial%20Bank%20Opp%20Lane%20NO.4,North%20Main%20Road,%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20Koregaon%20Park,Pune-411001%20Maharastra%20+(ClearMind)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
    );
  }

  render() {
    return (
      <div>
        <MDBFooter className="font-small pt-4 mt-8 footer-clr">
          <MDBContainer fluid className="text-center text-md-left">
            <hr></hr>
            <hr></hr>
            <MDBRow>
              <MDBCol md="3">
                <div className="logo-class">
                  <Link className="footer-img" to="/">
                    <img className="clogo" src={logo} alt="Cycle Logo" />
                  </Link>
                  <Link to="/">
                    <h5 className="footerTitle">CLEARMIND</h5>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md="3" className="cycle-product">
                <h5 className="title">
                  <b>PRODUCTS</b>
                </h5>
                <ul>
                  <li className="list-unstyled footer-list-one">
                    <Link to="/cycle">Cycle</Link>
                  </li>
                  <li className="list-unstyled footer-list-one">
                    <Link to="/deep">Deep-I</Link>
                  </li>
                </ul>
                <h5 className="title">
                  <b>SERVICE</b>
                </h5>
                <ul>
                  <li className="list-unstyled footer-list-one">
                    <Link to="/invest-advisory">Investment Advisory</Link>
                  </li>
                  <li className="list-unstyled footer-list-one">
                    <Link to="/backtesting">Backtesting</Link>
                  </li>
                </ul>
              </MDBCol>
              <MDBCol md="3" className="cycle-product">
                <Link to="/contact">
                  {" "}
                  <h5 className="titlefooter title">
                    <b>CONTACT </b>
                  </h5>
                </Link>
                <ul>
                  <li className="list-unstyled   footer-list">
                    {/* <a href="#!">Link 1</a> */}
                    <div style={{ display: "flex" }}>
                      <MDBIcon className="footer-icon" icon="map-marker-alt" />
                      <br />
                      <address className="check-address">
                        1st floor,Above Industial Bank Opp Lane NO.4,North Main
                        Road,Koregaon Park,Pune-411001 Maharastra
                      </address>
                    </div>
                  </li>
                  <li className="list-unstyled  footer-list">
                    <div>
                      <MDBIcon className="footer-phone" icon="phone-alt" />
                      <span className="phone">
                        <a href="tel:020 29510916">020 29510916</a>
                      </span>
                      <br />
                      <span className="phone" style={{ marginLeft: "10px" }}>
                        <a href="tel:+91 9372810916">+91 9372810916</a>
                      </span>
                    </div>
                  </li>
                  {/* <li className="list-unstyled">
                <a href="#!">Link 3</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">Link 4</a>
              </li> */}
                </ul>
              </MDBCol>
              <MDBCol md="3" className="cycle-product">
                {/* <h5 className="title">Links</h5> */}
                <ul>
                  {/* <li className="list-unstyled">
                    <Link to="/contact">
                      <h5 className="blog-footer">
                        <b>CONTACT US</b>
                      </h5>
                    </Link>
                  </li> */}
                  <li className="list-unstyled">
                    <Link to="/about">
                      <h5 className="blog-footer-one title">
                        <b>ABOUT</b>
                      </h5>
                    </Link>
                  </li>
                  <br></br>
                  <li className="list-unstyled"></li>
                  <li className="list-unstyled">
                    <div className="icon-div">
                      <a href="https://www.instagram.com/invites/contact/?i=1kzrgqkidcpo3&utm_content=l63i1m7">
                        {" "}
                        <MDBIcon
                          className="play-iconf"
                          fab
                          size="2x"
                          icon="instagram"
                        />
                      </a>
                      <a href="https://www.linkedin.com/company/iamclearmind/">
                        {" "}
                        <MDBIcon
                          className="play-iconf"
                          fab
                          size="2x"
                          icon="linkedin-in mdb-gallery-view-icon"
                        />
                      </a>
                      <a href="https://www.facebook.com/iamclearmind">
                        {" "}
                        <MDBIcon
                          className="play-iconf"
                          fab
                          size="2x"
                          icon="facebook"
                        />
                      </a>
                      <a href="https://twitter.com/iam_clearmind">
                        {" "}
                        <MDBIcon
                          className="play-iconf"
                          fab
                          size="2x"
                          icon="twitter"
                        />
                      </a>
                    </div>
                    {/* <a href="#"> <MDBIcon className="footer-social-icon" fab icon="twitter mdb-gallery-view-icon" /></a>
                <a href="#"> <MDBIcon  className="footer-social-icon"fab icon="google-play mdb-gallery-view-icon" /></a> */}
                  </li>
                </ul>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <hr className="hr"></hr>
          <div className="footer-copyright text-center py-3">
            <MDBContainer fluid>
              &copy; 2021 Clearmind. All rights reserved
            </MDBContainer>
          </div>
          {/* <hr></hr> */}
        </MDBFooter>
      </div>
    );
  }
}
export default footer;

//copyright function
// {new Date().getFullYear()}

//       <div className="footer-sub">
//         <div class="container">
//             <div class="row">
//               <div class="col-sm-3">

//     </div>
//     <div class="col-sm-6">
//    <Subscribe
//    subscribe="HomePage"/>
//     </div>
//     <div class="col-sm-3">

//     </div>
//   </div>
// </div>
