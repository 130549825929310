import React from "react";
import "./inventry.css";
function InventryHeading(props) {
  return (
    <div>
      <div className="heading-div">
        <div className="overlapping-c-div ">
          <h1 className="overlapping-heading">{props.heading}</h1>
          <div className="overlap-div">
            <h1 className="overlapping-main overlapping-main1 ">
              {props.subheading}{" "}
            </h1>
          </div>
        </div>

        <h4 style={{ color: "#E1EEFF" }}>{props.subpara}</h4>
        {/* <hr className="hr-details"></hr> */}
      </div>
    </div>
  );
}
export default InventryHeading;
