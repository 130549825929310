import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//components
// import Navbar from "./components/Navbar";
// import Footer from './components/footer'
import LandingPage from "./components/LandingPage";
import About from "./components/About";
import Contact from "./components/contact";
// import ProductLanding from "./components/ProductLanding";
// import Blog from "./components/Blog";
// import BlogDetails from "./components/BlogDetails";
import Cycle from "./components/cycle";
import Inventry from "./components/Inventry";
import DownloadButton from "./components/DownloadButton";
import Deep from "./components/Deep";
import Backtesting from "./components/Backtesting";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />

          <Route path="/cycle" exact component={Cycle} />
          <Route path="/deep" exact component={Deep} />
          <Route path="/invest-advisory" exact component={Inventry} />
          <Route path="/backtesting" exact component={Backtesting} />
          <Route path="/download-cycle" component={DownloadButton} />
        </Switch>
      </Router>
    );
  }
}

export default App;
