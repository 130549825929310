import React from "react";
import { MDBIcon } from "mdbreact";
import "./FeaturePage.css";

const FeaturesPage = (props) => {
  return (
    <div className="features_img pt20">
      <div className="features pt20">
        <div className="icon_text">
          {props.title1 == null || props.des1 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className="check-arrow check-arrow-feature"
              />
              <h4 className="mobilef-heading mobilef-heading2">
                {props.title1}
              </h4>
            </div>
          )}
          <div>
            <p className="featureText fs1 pt20">{props.des1}</p>
          </div>
        </div>
        <div className="icon_text">
          {props.title2 == null || props.des2 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className=" check-arrow check-arrow-feature"
              />
              <h4 className="mobilef-heading mobilef-heading2">
                {props.title2}
              </h4>
            </div>
          )}
          <div>
            <p className="featureText fs1 pt20">{props.des2} </p>
          </div>
        </div>
        <div className="icon_text">
          {props.title3 == null || props.des3 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className=" check-arrow check-arrow-feature"
              />
              <h4 className="mobilef-heading mobilef-heading2">
                {" "}
                {props.title3}
              </h4>
            </div>
          )}

          <div>
            <p className="featureText fs1 pt20">{props.des3} </p>
          </div>
        </div>
        <div className="icon_text">
          {props.title4 == null || props.des4 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className=" check-arrow check-arrow-feature"
              />
              <h4 className="mobilef-heading mobilef-heading2">
                {props.title4}
              </h4>
            </div>
          )}
          <div>
            <p className="featureText fs1 pt20">{props.des4}</p>
          </div>
        </div>
        <div className="icon_text">
          {props.title5 == null || props.des5 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className=" check-arrow check-arrow-feature"
              />
              <h4 className="mobilef-heading mobilef-heading2">
                {props.title5}
              </h4>
            </div>
          )}

          <div>
            <p className="featureText fs1 pt20">{props.des5}</p>
          </div>
        </div>

        <div className="icon_text">
          {props.title6 == null || props.des6 == null ? null : (
            <div className="iconX2">
              <MDBIcon
                icon="check"
                size="3x"
                className=" check-arrow check-arrow-feature"
              />
              <h4 className="mobilef-heading mobilef-heading2">
                {props.title6}
              </h4>
            </div>
          )}

          <div>
            <p className="featureText fs1 pt20">{props.des6}</p>
          </div>
        </div>
      </div>

      <div>
        <img className="img-Bussiness1 img-fluid " src={props.img1} alt="" />
      </div>
    </div>
  );
};

export default FeaturesPage;
